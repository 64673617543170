import React from 'react';
import cc from 'classcat';
import styles from './arrows.module.sass';
import { Icon } from '../../../Icon';
import { IconSVG } from '../../../SpriteSVG';

export const PrevArrow = ({ enabled, onClick }) => (
  <button
    className={cc([styles.emblaButton, styles.emblaButtonPrev])}
    onClick={onClick}
    disabled={!enabled}
  >
    <Icon
      icon={<IconSVG name={'common/gallery-arrow'} width={16} height={29} />}
    />
  </button>
);

export const NextArrow = ({ enabled, onClick }) => (
  <button
    className={cc([styles.emblaButton, styles.emblaButtonNext])}
    onClick={onClick}
    disabled={!enabled}
  >
    <Icon
      icon={
        <IconSVG
          name={'common/gallery-arrow'}
          width={16}
          height={29}
          alt="К следующему"
        />
      }
      title="К следующему"
    />
  </button>
);

export const PrevThumbArrow = ({ enabled, onClick }) => (
  <button
    className={cc([styles.emblaButton, styles.emblaButtonPrev])}
    onClick={onClick}
    disabled={!enabled}
  >
    <Icon
      className={styles.lg}
      icon={
        <IconSVG
          name={'common/gallery-thumb-arrow'}
          width={9}
          height={17}
          alt="К следующему"
        />
      }
      title="К следующему"
    />
  </button>
);

export const NextThumbArrow = ({ enabled, onClick }) => (
  <button
    className={cc([styles.emblaButton, styles.emblaButtonNext])}
    onClick={onClick}
    disabled={!enabled}
  >
    <Icon
      className={styles.lg}
      icon={
        <IconSVG
          name={'common/gallery-thumb-arrow'}
          width={9}
          height={17}
          alt="К следующему"
        />
      }
      title="К следующему"
    />
  </button>
);
