import React from 'react';
import cc from 'classcat';
import ReactPaginate from 'react-paginate';
import styles from './pagination.module.sass';

export const Pagination = ({ onChange, popularPageCount = 4, isLoading }) => {
  const handlePageClick = event => {
    if (onChange) {
      onChange({
        currentPage: event.selected + 1,
      });
    }
  };
  const disabled = isLoading;
  return (
    <nav>
      <ReactPaginate
        onPageChange={!disabled && handlePageClick}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={Math.ceil(popularPageCount)}
        nextLabel=""
        previousLabel=""
        disabledLinkClassName={styles.disabledArrow}
        disabledClassName={styles.disabledArrow}
        pageClassName={cc([
          styles.pageItem,
          { [styles.paginationDisabled]: disabled },
        ])}
        pageLinkClassName={cc([
          styles.pageItemLink,
          { [styles.paginationDisabled]: disabled },
        ])}
        previousClassName={cc([
          styles.arrowPrev,
          { [styles.disabledArrow]: disabled },
        ])}
        previousLinkClassName={cc([
          styles.arrowLink,
          { [styles.disabledArrow]: disabled },
        ])}
        nextClassName={cc([styles.arrow, { [styles.disabledArrow]: disabled }])}
        nextLinkClassName={cc([
          styles.arrowLink,
          { [styles.disabledArrow]: disabled },
        ])}
        breakLabel="..."
        breakClassName={styles.pageItem}
        breakLinkClassName={styles.pageItemLink}
        className={styles.pagination}
        activeClassName={styles.pageItemActive}
        renderOnZeroPageCount={null}
      />
    </nav>
  );
};
