import React from 'react';
import styles from './PopCruisesSkeleton.module.sass';
import { SkeletonCover } from '../Skeleton/SkeletonCover';
import { SkeletonCard } from '../Skeleton';

export const PopCruisesSkeleton = () => (
  <>
    <div className={styles.popularCruisesHeader}>
      <div>
        <SkeletonCover width={345} height={62} />
      </div>
      <div>
        <SkeletonCover width={308} height={62} />
      </div>
    </div>
    <div className={styles.listCard}>
      <SkeletonCard />
      <SkeletonCard />
      <SkeletonCard />
    </div>
  </>
);
