import { useState } from 'react';

export const useLimitList = ({ count, limit = 3 }) => {
  const [show, setShow] = useState(limit);

  const onShowMore = () => {
    setShow(show + limit);
  };

  return {
    show,
    onShowMore,
    isCanShowMore: count > limit && count > show,
  };
};
