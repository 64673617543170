import React, { useState } from 'react';
import Interweave from 'interweave';
import { Accordion } from '../Accordion';
import { Button } from '../Button';
import { YaAndGaCruisePage } from '../../lib/YaAndGaHelper';

/**
 * Компонент для скрытия контента
 * Переписать на нормальный компонент
 */
export const Collapsed = ({
  items,
  isOpen,
  shorter,
  isResult,
  isCruis,
  isSeo,
  seo,
  isPickCaute,
  onCloseExModal,
  onClickAction,
  targets,
  ...props
}) => {
  const [count, setCount] = useState(3);

  return (
    <>
      {isSeo && (
        <>
          {seo.map(({ title, description }) => (
            <Accordion isOpen={isOpen} title={title} {...props}>
              {description}
            </Accordion>
          ))}
        </>
      )}
      {isResult
        ? items
            ?.map(i => (
              <Accordion
                isOpen={isOpen}
                key={i.title}
                title={i.title}
                {...props}
              >
                <Interweave content={`${i.body.text}`} />
              </Accordion>
            ))
            .slice(0, shorter ? count : items.length)
        : items?.slice(0, shorter ? count : items.length)?.map(i => (
            <Accordion
              isOpen={isOpen}
              key={i?.title}
              title={isSeo ? i?.caption : i?.title}
              onCloseExModal={onCloseExModal}
              actionOnClick={() => {
                if (i.target) {
                  YaAndGaCruisePage([i.target]);
                }
              }}
              // actionOnClose={() => {
              //   if (targets) {
              //     YaAndGaCruisePage(targets[i?.closeTarger].close);
              //   }
              // }}
              {...props}
            >
              {!isCruis ? (
                <Interweave content={`${!isPickCaute ? i.body : i?.desc}`} />
              ) : (
                <div>{i.body}</div>
              )}
            </Accordion>
          )) ?? [].slice(0, shorter ? count : items.length)}
      {shorter && (
        <>
          {count < items?.length && (
            <Button
              onClick={() => {
                setCount(count + 3);
                YaAndGaCruisePage(onClickAction);
              }}
            >
              Загрузить ещё вопросы
            </Button>
          )}
        </>
      )}
    </>
  );
};

Collapsed.defaultProps = {
  isOpen: false,
  shorter: false,
};
