import React, { useState } from 'react';
import cc from 'classcat';
import styles from './accordion.module.sass';

export const Accordion = ({
  key,
  children,
  title,
  isOpen,
  onCloseExModal,
  actionOnClick,
}) => {
  const [isActive, setIsActive] = useState(isOpen);
  return (
    <div className={styles.accordion} key={key}>
      <div
        className="accordion-item"
        itemScope
        itemProp="mainEntity"
        itemType="https://schema.org/Question"
      >
        <button
          className={styles.accordionTitle}
          onClick={() => {
            setIsActive(!isActive);
            if (onCloseExModal) {
              onCloseExModal();
            }
            if (actionOnClick) {
              actionOnClick();
            }
          }}
        >
          <h2 className={styles.innerTitle} itemProp="name">
            {title}
          </h2>
          <div className={isActive ? styles.open : styles.close} />{' '}
        </button>

        <div
          className={cc([
            styles.accordionContent,
            isActive && styles.accordionContentActive,
          ])}
          itemScope
          itemProp="acceptedAnswer"
          itemType="https://schema.org/Answer"
        >
          <div itemProp="text">{children}</div>
        </div>
      </div>
    </div>
  );
};
