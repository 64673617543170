import React from 'react';
import styles from './PopDesckSkeleton.module.sass';
import { SkeletonCover } from '../Skeleton/SkeletonCover';

export const PopDestSkeleton = () => (
  <div className={styles.directions}>
    <div>
      <SkeletonCover width={400} height={40} />
    </div>
    <div className={styles.skeletonWrapper}>
      <div className={styles.skeletonBlock}>
        <div className={styles.skeleton}>
          <SkeletonCover width={535} height={80} />
        </div>
        <div className={styles.skeleton}>
          <SkeletonCover width={535} height={80} />
        </div>
        <div className={styles.skeleton}>
          <SkeletonCover width={535} height={80} />
        </div>{' '}
      </div>
      <div>
        <div className={styles.skeleton}>
          <SkeletonCover width={535} height={80} />
        </div>
        <div className={styles.skeleton}>
          <SkeletonCover width={535} height={80} />
        </div>
        <div className={styles.skeleton}>
          <SkeletonCover width={535} height={80} />
        </div>{' '}
      </div>{' '}
    </div>
  </div>
);
