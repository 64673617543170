import React from 'react';
import cc from 'classcat';
import styles from './dots.module.sass';
import { isClient } from '../../utils/isClient';

export const Dots = ({
  view,
  count,
  active,
  onSelect,
  spaceBetween,
  topGap,
}) => (
  <div
    className={cc([styles.dots, styles[view]])}
    style={{ paddingTop: `${topGap} px` }}
  >
    {isClient &&
      new Array(count).fill(0).map((_, index) => (
        <button
          key={index}
          aria-label="Select"
          style={{ margin: `0 ${spaceBetween}px` }}
          onClick={() => {
            if (onSelect) onSelect(index);
          }}
          className={cc({ [styles.active]: active === index })}
        />
      ))}
  </div>
);

Dots.defaultProps = {
  view: 'regularDots',
  spaceBetween: 4,
  topGap: 16,
  onSelect: null,
};
