import { useEffect } from 'react';
import d from 'dayjs';
import { parseCookies } from 'nookies';

export const useHiddenWithCookie = func => {
  const getCookie = parseCookies();
  const currentDate = d();
  const timeEnd = d(getCookie?.timeToOpenBanner);
  const timeToOpen = timeEnd <= currentDate;

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (timeToOpen) {
      func();
    }
  }, []);
};
