import React from 'react';
import cc from 'classcat';
import { If, Then } from 'react-if';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import { useSearchStore } from '../../providers/RootStoreProvider';
import { Text } from '../Text';
import styles from './populardestinations.module.sass';

import { YaAndGaMainPage } from '../../lib/YaAndGaHelper';
import { formatter } from '../../utils/formatter';
// import { SkeletonCover } from '../Skeleton/SkeletonCover';
import { PopDestSkeleton } from '../PopDestSkeleton';
// import { PopDestinationsSkeleton } from '../PopularDestinationsSkeleton';
import { Image } from '../Image/Image';

export const PopularDestinations = observer(
  ({ noTitle, noPaddings, isMobile, riverPopDest, seaPopDest }) => {
    const router = useRouter();
    const store = useSearchStore();
    const isLoading = store.popularIsLoading;

    const isSea =
      router.asPath.startsWith('/sea') ||
      router.asPath.startsWith('/sea_cruise');

    return (
      <>
        {!isLoading ? (
          <div>
            {isSea ? (
              <section
                className={cc([
                  styles.directions,
                  { [styles.skinny]: noPaddings },
                ])}
              >
                <If condition={!noTitle}>
                  <Then>
                    <Text variant="heading-1" component="h2">
                      Популярные направления
                    </Text>
                  </Then>
                </If>
                <div className={styles.directionsElements}>
                  {seaPopDest &&
                    seaPopDest.map((popDest, key) => (
                      <a
                        className={styles.directionsElement}
                        href={`/popdir/${popDest.href}`}
                        rel="noreferrer"
                        key={key}
                        onClick={() => {
                          YaAndGaMainPage(
                            'click_any_departure_in_popular_cruises',
                          );
                        }}
                      >
                        <Image
                          src={`/images/popdir/directions/${popDest.img.src}`}
                          srcSet={`/images/popdir/directions/${popDest.img.srcset}`}
                          alt="Иконка города"
                          className={styles.roundSeaImageDirections}
                          loading={'lazy'}
                        />

                        <div className={styles.directionsElementText}>
                          <Text variant="heading-6">{popDest.caption}</Text>
                          {/* <Text>от {formatter(popDest.min_price)} ₽</Text> */}
                        </div>
                      </a>
                    ))}
                </div>
              </section>
            ) : (
              <>
                <section
                  className={cc([
                    styles.directions,
                    { [styles.skinny]: noPaddings },
                  ])}
                >
                  <If condition={!noTitle}>
                    <Then>
                      <Text variant="heading-1" component="h2">
                        Популярные направления
                      </Text>
                    </Then>
                  </If>
                  <div className={styles.directionsElements}>
                    {riverPopDest &&
                      riverPopDest.map((popDest, key) => (
                        <a
                          className={styles.directionsElement}
                          href={`/popdir/${popDest.href}/result`}
                          rel="noreferrer"
                          key={key}
                          onClick={() => {
                            YaAndGaMainPage(
                              'click_any_departure_in_popular_cruises',
                            );
                          }}
                        >
                          <Image
                            src={`/images/popdir/directions/${popDest.img_new.src}`}
                            srcSet={`/images/popdir/directions/${popDest.img_new.srcset}`}
                            alt="Иконка города"
                            className={styles.roundImageDirections}
                            loading={'lazy'}
                          />
                          <div className={styles.directionsElementText}>
                            <Text variant="heading-6">{popDest.caption}</Text>
                            <Text>от {formatter(popDest.min_price)} ₽</Text>
                          </div>
                        </a>
                      ))}
                  </div>
                </section>
              </>
            )}
          </div>
        ) : (
          <PopDestSkeleton />
        )}
      </>
    );
  },
);
