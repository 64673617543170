import React from 'react';
import ContentLoader from 'react-content-loader';
import styles from './Skeleton.module.sass';

export const SkeletonCard = ({ animate, ...props }) => (
  <div className={styles.card}>
    <ContentLoader
      speed={1}
      width={400}
      animate={animate}
      viewBox="0 0 358 562"
      backgroundColor="#F3F6F9"
      foregroundColor="#F9F9F9"
      style={{ maxWidth: '100%', minWidth: '100%' }}
      {...props}
    >
      <rect x="0" y="8" rx="0" ry="0" width="358" height="40" />
      <rect x="0" y="60" rx="0" ry="0" width="358" height="224" />
      <rect x="8" y="292" rx="0" ry="0" width="342" height="20" />
      <rect x="8" y="316" rx="0" ry="0" width="285" height="20" />
      <rect x="8" y="340" rx="0" ry="0" width="260" height="24" />
      <rect x="8" y="400" rx="0" ry="0" width="167" height="24" />
      <rect x="8" y="370" rx="0" ry="0" width="218" height="24" />
      <rect x="8" y="430" rx="0" ry="0" width="153" height="24" />
      <rect x="8" y="460" rx="0" ry="0" width="342" height="1" />
      <rect x="8" y="493" rx="0" ry="0" width="125" height="24" />
      <rect x="174" y="476" rx="4" ry="4" width="170" height="56" />
    </ContentLoader>
  </div>
);
